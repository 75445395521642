<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <!-- MODAL -->
  <div
    class="modal fade"
    id="new-comment-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    style="margin-top: 30px"
    @click.self="cancelModal"
  >
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4>Novo comentário</h4>
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title" style="color: white"></h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <!-- COMMENT -->
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="description">Comentário</label>
                      <textarea id="description" rows="6" cols="50" class="form-control" v-model="formData.comment" />

                      <span style="color: red" v-for="error in v$.comment.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <button data-toggle="modal" class="btn btn-success" @click="validate">Cadastrar</button>
                <button
                  style="margin-left: 15px"
                  data-toggle="modal"
                  id="close_create_comment"
                  data-target="#new-comment-modal"
                  class="btn btn-danger"
                  @click="this.setCreateComment(false)"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import sweetAlert from "../../controller/sweetAlert.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AuxController from "../../mixins/auxController.js";
import Loading from "vue-loading-overlay";
import moment, { relativeTimeThreshold } from "moment";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  props: ["task", "setCreateComment", "commentCreated"],

  mixins: [sweetAlert, Swal, AuxController],

  components: { Loading },

  data() {
    return {
      isLoading: false,
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      tests: [],
      acao: "",
      titulo_btn: "",
      titulo_modal: "",
      selected_task: "",
    };
  },

  setup() {
    const formData = reactive({
      comment: "",
    });

    const rules = computed(() => {
      return {
        comment: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    console.log("Create test");
    console.log(this.projects);
  },

  methods: {
    cancelModal() {
      this.setCreateComment(false);
    },

    async validate() {
      const result = await this.v$.$validate();

      if (result) {
        this.store();
      }
    },

    store() {
      this.isLoading = true;

      let data = {
        task_id: this.task.id,
        comment: this.formData.comment,
      };

      console.log("Cadastrando");
      console.log(data);

      axios
        .post(`/admin/comment/store`, data)
        .then((response) => {
          this.isLoading = false;

          this.showSuccessMessageWithButton("Sucesso", "Comentário cadastrado");

          this.commentCreated();
        })
        .catch((error) => {
          this.isLoading = false;
          this.showErrorMessageWithButton("Ops..", error);
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.current-page {
  background-color: #428bca !important;
  color: white;
}

.modal-content {
  width: 1000px;
  margin-left: -150px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
