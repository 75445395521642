require('./bootstrap');

import { createApp } from "vue";
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import VueClipboard from 'vue-clipboard2'
import axios from 'axios';
import VueAxios from 'vue-axios'

//ADMIN 
import TaskDetails from "./components/admin/tasks/Details.vue";
import ListTasks from "./components/admin/tasks/List.vue";
import ListProjects from "./components/admin/projects/List.vue";

import ListTests from "./components/admin/tests/List.vue";
import CreateTest from "./components/admin/tests/Create.vue";
import EditTest from "./components/admin/tests/Edit.vue";

import ListComments from "./components/admin/comments/List.vue";
import CreateComment from "./components/admin/comments/Create.vue";
import EditComment from "./components/admin/comments/Edit.vue";

import PrimeVue from 'primevue/config';

//OPÇÃO 3
const vue = createApp({
    components: {}
})

vue.use(PrimeVue)
vue.use(VueAxios, axios)
vue.use(VueClipboard)
vue.use(VueSweetalert2);
vue.use(router)

//ADMIN 

//TASKS
vue.component('list-tasks', ListTasks)
vue.component('task-details', TaskDetails)

//PROJETOS
vue.component('list-projects', ListProjects)
vue.component('task-details', TaskDetails)

//TESTS
vue.component('list-tests', ListTests)
vue.component('create-test', CreateTest)
vue.component('edit-test', EditTest)

//COMMENTS
vue.component('list-comments', ListComments)
vue.component('create-comment', CreateComment)
vue.component('edit-comment', EditComment)

vue.mount("#app")