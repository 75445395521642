<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />

  <!-- MODAL -->
  <div
    class="modal fade"
    id="create-test-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    style="font-size: 16px; margin-top: 30px"
    @click.self="cancelModal"
  >
    <form @submit.prevent>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body" style="padding: 0; margin: 0">
            <div class="card card-primary" style="padding: 0; margin: 0">
              <div class="card-header">
                <h3 class="card-title" style="color: white">Novo teste</h3>
                <div class="card-tools">
                  <ul class="nav nav-pills ml-auto">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      style="margin-right: 8px; margin-top: 4px; color: red"
                    >
                      &times;
                    </button>
                  </ul>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <!-- DESCRIPTION -->
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="description">Descrição</label>
                      <Editor style="background-color: white" v-model="formData.description" editorStyle="height: 200px" />

                      <span style="color: red" v-for="error in v$.description.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </span>
                    </div>
                  </div>

                  <!-- RESULT -->
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="description">Resultado</label>
                      <Editor style="background-color: white" v-model="formData.result" editorStyle="height: 200px" />
                    </div>
                  </div>

                  <!-- STATUS -->
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="description">Status</label>
                      <select class="custom-select rounded-0" v-model="formData.status">
                        <option :value="0">0- Pendente</option>
                        <option :value="10">10- Aprovada</option>
                        <option :value="20">20- Reprovada</option>
                        <option :value="90">90- Cancelada</option>
                      </select>

                      <span style="color: red" v-for="error in v$.status.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <button data-toggle="modal" class="btn btn-success" @click="validate">Cadastrar</button>
                <button
                  @click="this.setCreateTest(false)"
                  style="margin-left: 15px"
                  data-toggle="modal"
                  id="close_create_test"
                  data-target="#create-test-modal"
                  class="btn btn-danger"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import sweetAlert from "../../controller/sweetAlert.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AuxController from "../../mixins/auxController.js";
import Loading from "vue-loading-overlay";
import moment, { relativeTimeThreshold } from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import Editor from "primevue/editor";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  props: ["task", "setCreateTest", "testCreated"],

  mixins: [sweetAlert, Swal, AuxController],

  components: { Loading, Editor },

  data() {
    return {
      isLoading: false,
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      tests: [],
      acao: "",
      titulo_btn: "",
      titulo_modal: "",
      selected_task: "",
    };
  },

  setup() {
    const formData = reactive({
      description: "",
      status: "",
    });

    const rules = computed(() => {
      return {
        description: { required },
        status: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    console.log("Create test");
    console.log(this.projects);
  },

  methods: {
    cancelModal() {
      this.setCreateTest(false);
    },

    async validate() {
      const result = await this.v$.$validate();

      if (result) {
        this.store();
      }
    },

    store() {
      this.isLoading = true;

      let data = {
        task_id: this.task.id,
        status: this.formData.status,
        description: this.formData.description,
        result: this.formData.result,
      };

      console.log("Cadastrando");
      console.log(data);

      axios
        .post(`/admin/test/store`, data)
        .then((response) => {
          this.isLoading = false;

          this.showSuccessMessageWithButton("Sucesso", "Teste cadastrado");
          this.testCreated();
        })
        .catch((error) => {
          this.isLoading = false;
          this.showErrorMessageWithButton("Ops..", error);
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.current-page {
  background-color: #428bca !important;
  color: white;
}

.modal-content {
  width: 1000px;
  margin-left: -150px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
