<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div>
    <br />
    <h2>
      Tasks
      <button data-toggle="modal" data-target="#nova-obra-modal" style="margin-left: 15px" type="button" class="btn btn-primary" @click="limparObra">
        <i class="fas fa-plus" style="margin-right: 5px"></i>
        Nova task
      </button>
    </h2>
    <br />
    <!-- FILTROS -->
    <div class="row" style="width: 100%">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form v-on:submit.prevent="buscartasks()">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="input-group mb-3">
                      <input class="form-control" id="id" v-model="filtro" placeholder="Buscar tasks" />

                      <span class="input-group-append">
                        <button @click="buscartasks()" style="border-radius: 2px" type="submit" class="btn btn-info">
                          <i class="fas fa-search" style="margin-right: 5px"></i>
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 0px 0px 10px 0px; color: red" v-if="searchBuscado">Exibindo resultados para "{{ filtro }}"</div>

    <!-- TASKS -->
    <div class="card">
      <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap" style="position: relative">
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>Título</th>
              <th>Sprint</th>
              <th>Grupo</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(task, index) in tasks" :key="tasks" @contextmenu.prevent="onRightClick($event, task)" @click="hideMenu()">
              <td data-label="Seq">
                <a
                  style="padding: 2px 5px 2px 5px; background-color: green; border-radius: 50%; border-style: none"
                  :href="'/admin/task/details/' + task.id"
                >
                  <i class="fa-solid fa-arrow-right" style="background-color: green; color: white"></i>
                </a>
              </td>

              <td data-label="Id">{{ task.id }}</td>
              <td data-label="Título">{{ task.title }}</td>
              <td data-label="Sprint">{{ task.sprint }}</td>
              <td data-label="Grupo">{{ task.grupo }}</td>
              <td data-label="Título">
                <label v-if="task.status == 0">0- Criada</label>
                <label v-if="task.status == 10">10- Liberada</label>
                <label v-if="task.status == 20">20- Em andamento</label>
                <label v-if="task.status == 30">30-Finalizada</label>
                <label v-if="task.status == 40">40-Teste</label>
                <label v-if="task.status == 50">50-A corrigir</label>
                <label v-if="task.status == 60">60-Teste OK</label>
                <label v-if="task.status == 90">90-Cancelada</label>
                <label v-if="task.status == 100">100-A entregar</label>
                <label v-if="task.status == 110">110-Encerrada</label>
              </td>

              <td data-label="Editar">
                <a
                  id="navbarDropdown"
                  class="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  v-pre
                >
                  <i class="fa-solid fa-bars" style="color: gray"></i>
                </a>

                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" aria-labelledby="navbarDropdown" ref="menu">
                  <div class="dropdown-divider"></div>
                  <a @click="selectTask(task)" data-toggle="modal" data-target="#nova-obra-modal" class="dropdown-item">
                    <i class="fa-solid fa-pen"></i>
                    &nbsp;&nbsp; Editar
                  </a>

                  <div class="dropdown-divider"></div>
                  <a @click="deletar(task)" class="dropdown-item">
                    <i class="fa-solid fa-bars"></i>
                    &nbsp;&nbsp; Deletar
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          id="newMenu"
          style="visibility: hidden; position: absolute; background-color: white; max-width: 300px; line-height: 15px"
          class="dropdown-menu-lg dropdown-menu-right"
        >
          <div class="dropdown-divider"></div>
          <a style="cursor: pointer" @click="selectTask(selected_task)" class="dropdown-item" data-toggle="modal" data-target="#nova-obra-modal">
            <i class="fa-solid fa-pen"></i>
            &nbsp;&nbsp; Editar
          </a>

          <div class="dropdown-divider"></div>
          <a style="cursor: pointer" @click="deletar(selected_task)" class="dropdown-item">
            <i class="fa-solid fa-bars"></i>
            &nbsp;&nbsp; Deletar
          </a>
        </div>
      </div>
      <div class="card-footer clearfix">
        <!-- TOTAIS  -->
        <div style="font-size: 13px" class="float-left" aria-live="polite">
          Exibindo do {{ inicio + 1 }} ao {{ inicio + tasks.length }}
          <p>Total {{ total }}</p>
        </div>

        <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

        <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="7">7</option>
          <option :value="15">15</option>
          <option :value="25">25</option>
        </select>

        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 150px"></div>
        </div>

        <!-- PAGINAÇÃO  -->
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item"><a class="page-link" href="#" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

          <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
            <a :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'" class="page-link" href="#" @click="selecionarPagina(pagina)">
              {{ pagina }}
            </a>
          </li>

          <li class="page-item"><a class="page-link" href="#" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
        </ul>
      </div>
    </div>

    <!-- MODAL -->
    <div
      class="modal fade"
      id="nova-obra-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
      style="margin-top: 30px"
    >
      <form @submit.prevent>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              <h4>{{ titulo_modal }}</h4>
              <br />
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title" style="color: white"></h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!-- PROJECT -->
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="project_id">Projeto</label>
                        <select id="project_id" class="custom-select rounded-0" v-model="formData.project_id" @change="searchGroups">
                          <option v-for="project in projects" :key="project" :value="project.id">
                            {{ project.name }}
                          </option>
                        </select>

                        <span style="color: red" v-for="error in v$.project_id.$errors" :key="error.$uid">
                          {{ error.$message }}
                        </span>
                      </div>
                    </div>

                    <!-- REQUESTER -->
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="requester">Solicitante</label>

                        <select id="requester" v-model="formData.requester" class="custom-select rounded-0">
                          <option value="dev">Dev</option>
                          <option value="user">User</option>
                        </select>

                        <span style="color: red" v-for="error in v$.requester.$errors" :key="error.$uid">
                          {{ error.$message }}
                        </span>
                      </div>
                    </div>

                    <!-- TYPE-->
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="type">Tipo</label>

                        <select id="type" v-model="formData.type" class="custom-select rounded-0">
                          <option value="bug">Bug</option>
                          <option value="feature">Feature</option>
                          <option value="task">Task</option>
                        </select>

                        <span style="color: red" v-for="error in v$.type.$errors" :key="error.$uid">
                          {{ error.$message }}
                        </span>
                      </div>
                    </div>

                    <!-- RESPONSABLE -->
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="responsable">Responsável</label>
                        <input class="form-control" id="responsable" v-model="formData.responsable" />

                        <span style="color: red" v-for="error in v$.responsable.$errors" :key="error.$uid">
                          {{ error.$message }}
                        </span>
                      </div>
                    </div>

                    <!-- TITULO -->
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="title">Título</label>
                        <input class="form-control" id="title" v-model="formData.title" />

                        <span style="color: red" v-for="error in v$.title.$errors" :key="error.$uid">
                          {{ error.$message }}
                        </span>
                      </div>
                    </div>

                    <!-- STATUS -->
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="title">Status</label>
                        <select class="custom-select rounded-0" v-model="formData.status">
                          <option :value="0">0- Criada</option>
                          <option :value="10">10- Liberada</option>
                          <option :value="20">20- Em andamento</option>
                          <option :value="30">30- Finalizada</option>
                          <option :value="40">40- Teste</option>
                          <option :value="50">50- A corrigir</option>
                          <option :value="60">60- Teste OK</option>
                          <option :value="90">90- Cancelada</option>
                          <option :value="100">100- A entregar</option>
                          <option :value="110">110- Encerrada</option>
                        </select>

                        <span style="color: red" v-for="error in v$.status.$errors" :key="error.$uid">
                          {{ error.$message }}
                        </span>
                      </div>
                    </div>

                    <!-- SPRINT -->
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="project_id">Sprint</label>
                        <select id="project_id" class="custom-select rounded-0" v-model="formData.sprint_id">
                          <option v-for="sprint in sprints" :key="sprint" :value="sprint.id">
                            {{ sprint.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- GROUPS -->
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="project_id">Grupo</label>
                        <select id="project_id" class="custom-select rounded-0" v-model="formData.group_id">
                          <option v-for="group in groups" :key="group" :value="group.id">
                            {{ group.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- DESCRIPTION -->
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label for="description">Descrição</label>
                        <Editor style="background-color: white" v-model="formData.description" editorStyle="height: 200px" />

                        <span style="color: red" v-for="error in v$.description.$errors" :key="error.$uid">
                          {{ error.$message }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <button data-toggle="modal" class="btn btn-success" @click="validate">{{ titulo_btn }}</button>
                  <button style="margin-left: 15px" data-toggle="modal" id="cancelar" data-target="#nova-obra-modal" class="btn btn-danger">
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AuxController from "../../mixins/auxController.js";
import Loading from "vue-loading-overlay";
import moment, { relativeTimeThreshold } from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import Editor from "primevue/editor";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  props: ["projects", "sprints"],

  mixins: [sweetAlert, Swal, AuxController],

  components: { Loading, Editor },

  data() {
    return {
      isLoading: false,
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      tasks: [],
      groups: [],
      acao: "",
      titulo_btn: "",
      titulo_modal: "",
      selected_task: "",
    };
  },

  setup() {
    const formData = reactive({
      project_id: "",
      requester: "",
      type: "",
      description: "",
      title: "",
      responsable: "",
      status: "",
      group_id: "",
      sprint_id: "",
    });

    const rules = computed(() => {
      return {
        project_id: { required },
        requester: { required },
        type: { required },
        description: { required },
        title: { required },
        responsable: { required },
        status: { required },
      };
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    console.log(this.projects);

    this.buscartasks();
  },

  methods: {
    hideMenu() {
      let newMenu = document.getElementById("newMenu");
      newMenu.style.visibility = "hidden";
    },

    onRightClick(event, task) {
      this.selected_task = task;

      let newMenu = document.getElementById("newMenu");
      let x = event.clientX - 300;
      let y = event.pageY - 250;

      let winWidth = window.innerWidth;
      let cmWidth = newMenu.offsetWidth;

      x = event.clientX > winWidth - cmWidth ? winWidth - cmWidth - 300 : x;

      newMenu.style.top = y + "px";
      newMenu.style.left = x + "px";
      newMenu.style.visibility = "visible";
    },

    limparObra() {
      this.titulo_btn = "Cadastrar";
      this.titulo_modal = "Nova task";
      this.acao = "store";

      this.formData.project_id = "";
      this.formData.requester = "";
      this.formData.type = "";
      this.formData.description = "";
      this.formData.title = "";
      this.formData.responsable = "";
    },

    selectTask(task) {
      console.log("Selecionada");
      console.log(task);

      this.titulo_btn = "Gravar";
      this.titulo_modal = "Editar obra ID " + task.id;
      this.acao = "update";

      this.selected_task = task;
      this.formData.project_id = task.project_id;
      this.formData.requester = task.requester;
      this.formData.type = task.type;
      this.formData.description = task.description;
      this.formData.title = task.title;
      this.formData.responsable = task.responsable;
      this.formData.status = task.status;
      this.formData.group_id = task.group_id;
      this.formData.sprint_id = task.sprint_id;

      this.searchGroups();
    },

    searchGroups() {
      this.isLoading = true;

      let data = {
        project_id: this.formData.project_id,
      };

      console.log("Buscando grupos");
      console.log(data);

      axios
        .post(`/admin/groups/search_by_project`, data)
        .then((response) => {
          this.isLoading = false;
          this.groups = response.data.groups;
        })
        .catch((error) => {
          this.isLoading = false;
          this.showErrorMessageWithButton("Ops..", error);
          console.log(error);
        });
    },

    async validate() {
      const result = await this.v$.$validate();

      if (result) {
        if (this.acao == "store") {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.isLoading = true;

      let data = {
        project_id: this.formData.project_id,
        requester: this.formData.requester,
        type: this.formData.type,
        description: this.formData.description,
        title: this.formData.title,
        responsable: this.formData.responsable,
        group_id: this.formData.group_id,
        sprint_id: this.formData.sprint_id,
      };

      console.log("Cadastrando");
      console.log(data);

      axios
        .post(`/admin/task/store`, data)
        .then((response) => {
          this.isLoading = false;

          this.showSuccessMessageWithButtonAndRedirect("Sucesso", "Task cadastrada", "/admin");
        })
        .catch((error) => {
          this.isLoading = false;
          this.showErrorMessageWithButton("Ops..", error);
          console.log(error);
        });
    },

    update() {
      this.isLoading = true;

      let data = {
        task_id: this.selected_task.id,
        project_id: this.formData.project_id,
        requester: this.formData.requester,
        type: this.formData.type,
        description: this.formData.description,
        title: this.formData.title,
        responsable: this.formData.responsable,
        status: this.formData.status,
        group_id: this.formData.group_id,
        sprint_id: this.formData.sprint_id,
      };

      console.log("Atualizando");
      console.log(data);

      axios
        .post(`/admin/task/update`, data)
        .then((response) => {
          this.isLoading = false;

          this.showSuccessMessageWithButtonAndRedirect("Sucesso", "Task atualizada", "/admin");
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error);
          console.log(error);
        });
    },

    calcularValor(created_at) {
      let dataReferencia = this.formatToCompare(new Date());
      let data = this.formatToCompare(created_at);

      var duration = moment.duration(dataReferencia.diff(data));
      var hours = duration.asHours();

      if (hours < 1) {
        var min = duration.asMinutes();
        return ~~min + " min.";
      } else if (hours >= 24) {
        var days = duration.asDays();
        return ~~days + " dias";
      } else {
        return ~~hours + " horas";
      }
    },

    hideMenu() {
      let newMenu = document.getElementById("newMenu");
      newMenu.style.visibility = "hidden";
    },

    onChange(e) {
      this.formData.upload_files = e.target.files[0];
    },

    visualizar(leilao) {
      this.leilao_selecionado = leilao;
    },

    formatToCompare(date) {
      return moment(date);
    },

    convertTiny(value) {
      if (value == 0) {
        return "Não";
      } else {
        return "Sim";
      }
    },

    convertToTiny(value) {
      if (value) {
        return 1;
      } else {
        return 0;
      }
    },

    getUrlEdit(leilao) {
      return "/admin/leilao/" + leilao.id;
    },

    show(leilao) {
      this.leilao_selecionado = leilao;
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.buscartasks();
      }
    },

    buscartasks() {
      this.isLoading = true;

      let data = {
        id: this.id,
        inicio: this.inicio,
        filtro: this.filtro,
        tamanho: this.qtd_por_pagina,
      };

      console.log(data);

      if (this.filtro && this.filtro != "") {
        this.searchBuscado = true;
      } else {
        this.searchBuscado = false;
      }

      axios
        .post(`/admin/tasks/search`, data)
        .then((response) => {
          this.isLoading = false;
          this.tasks = response.data.tasks;
          this.total = response.data.total;

          console.log("tasks");
          console.log(this.tasks);

          this.pagination(response.data);
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    getImagePath(logo) {
      return process.env.MIX_VUE_APP_LOGOS_ENDPOINT + "/" + logo.caminho_foto;
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.buscartasks();
    },

    deletar(obra) {
      this.$swal
        .fire({
          title: "<h3 style='color:#616060'>Deseja deletar a obra<br/><b>" + obra.titulo + "</b>? </h3>",
          icon: "warning",
          padding: "1.5em",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, quero deletar!",
        })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;

            let data = {
              obra_id: obra.id,
            };

            axios
              .post(`/admin/obra/delete`, data)
              .then((response) => {
                this.isLoading = false;
                this.showSuccessMessageWithButtonAndRedirect("Sucesso", "Obra deletada", "/admin");
              })
              .catch((error) => {
                this.isLoading = false;
                this.showErrorMessageWithButton("Ops..", error.response.data);
                console.log(error.response.data);
              });
          }
        });
    },

    validateFields() {
      if (!this.nome) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else {
        this.create();
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm");
    },
  },
};
</script>

<style scoped>
.current-page {
  background-color: #428bca !important;
  color: white;
}

.modal-content {
  width: 1000px;
  margin-left: -150px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
