<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div>
    <br />
    <h2>
      <a
        style="cursor: pointer; padding: 0px 5px 0px 5px; background-color: #0d6efd; border-radius: 50%; border-style: none"
        onclick="history.back()"
      >
        <i class="fa-solid fa-arrow-left" style="background-color: #0d6efd; color: white; border-radius: 50%"></i>
      </a>
      Task #{{ task.id }}
    </h2>
    <br />

    <!-- TASK DETAILS -->
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title" style="color: white">Detalhes da task</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <!-- PROJECT -->
          <div class="col-sm-3">
            <div class="form-group">
              <label for="project_id">Projeto</label>
              <select id="project_id" class="custom-select rounded-0" v-model="formData.project_id">
                <option v-for="project in projects" :key="project" :value="project.id">
                  {{ project.name }}
                </option>
              </select>

              <span v-if="formData.update_task" style="color: red" v-for="error in v$.project_id.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>
          </div>

          <!-- REQUESTER -->
          <div class="col-sm-3">
            <div class="form-group">
              <label for="requester">Solicitante</label>

              <select id="requester" v-model="formData.requester" class="custom-select rounded-0">
                <option value="dev">Dev</option>
                <option value="user">User</option>
              </select>

              <span v-if="formData.update_task" style="color: red" v-for="error in v$.requester.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>
          </div>

          <!-- TYPE-->
          <div class="col-sm-3">
            <div class="form-group">
              <label for="type">Tipo</label>

              <select id="type" v-model="formData.type" class="custom-select rounded-0">
                <option value="bug">Bug</option>
                <option value="feature">Feature</option>
                <option value="task">Task</option>
              </select>

              <span v-if="formData.update_task" style="color: red" v-for="error in v$.type.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>
          </div>

          <!-- RESPONSABLE -->
          <div class="col-sm-3">
            <div class="form-group">
              <label for="responsable">Responsável</label>
              <input class="form-control" id="responsable" v-model="formData.responsable" />

              <span v-if="formData.update_task" style="color: red" v-for="error in v$.responsable.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>
          </div>

          <!-- TITULO -->
          <div class="col-sm-6">
            <div class="form-group">
              <label for="title">Título</label>
              <input class="form-control" id="title" v-model="formData.title" />

              <span v-if="formData.update_task" style="color: red" v-for="error in v$.title.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>
          </div>

          <!-- STATUS -->
          <div class="col-sm-6">
            <div class="form-group">
              <label for="title">Status</label>
              <select class="custom-select rounded-0" v-model="formData.status">
                <option :value="0">0- Criada</option>
                <option :value="10">10- Liberada</option>
                <option :value="20">20- Em andamento</option>
                <option :value="30">30- Finalizada</option>
                <option :value="40">40- Teste</option>
                <option :value="50">50- A corrigir</option>
                <option :value="60">60- Teste OK</option>
                <option :value="90">90- Cancelada</option>
                <option :value="100">100- A entregar</option>
                <option :value="110">110- Encerrada</option>
              </select>

              <span v-if="formData.update_task" style="color: red" v-for="error in v$.status.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>
          </div>

          <!-- DESCRIPTION -->
          <div class="col-sm-12">
            <div class="form-group">
              <label for="description">Descrição</label>
              <Editor style="background-color: white" v-model="formData.description" editorStyle="height: 200px" />

              <span v-if="formData.update_task" style="color: red" v-for="error in v$.description.$errors" :key="error.$uid">
                {{ error.$message }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer clearfix">
        <button data-toggle="modal" class="btn btn-success" @click="validate">Gravar</button>
      </div>
    </div>

    <div @click="formData.update_task = false">
      <list-comments :task="task" />
    </div>

    <div @click="formData.update_task = false">
      <list-tests :task="task" />
    </div>
  </div>
</template>

<script>
import sweetAlert from "../../controller/sweetAlert.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AuxController from "../../mixins/auxController.js";
import Loading from "vue-loading-overlay";
import moment, { relativeTimeThreshold } from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import Editor from "primevue/editor";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  props: ["task", "projects"],

  mixins: [sweetAlert, Swal, AuxController],

  components: { Loading, Editor },

  data() {
    return {
      isLoading: false,
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      tasks: [],
      acao: "",
      titulo_btn: "",
      titulo_modal: "",
      selected_task: "",
      comments: [],
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      update_task: false,
    };
  },

  setup() {
    const formData = reactive({
      project_id: "",
      requester: "",
      type: "",
      description: "",
      title: "",
      responsable: "",
      status: "",
      update_task: false,
    });

    const rules = computed(() => {
      let local_rules = {};

      if (formData.update_task) {
        local_rules.project_id = {
          required,
        };

        local_rules.requester = {
          required,
        };

        local_rules.type = {
          required,
        };

        local_rules.description = {
          required,
        };

        local_rules.title = {
          required,
        };

        local_rules.responsable = {
          required,
        };

        local_rules.status = {
          required,
        };
      }

      return local_rules;
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    console.log(this.projects);

    this.searchComments();

    this.selectTask(this.task);
  },

  methods: {
    selectTask(task) {
      console.log("Selecionada");
      console.log(task);

      this.formData.project_id = task.project_id;
      this.formData.requester = task.requester;
      this.formData.type = task.type;
      this.formData.description = task.description;
      this.formData.title = task.title;
      this.formData.responsable = task.responsable;
      this.formData.status = task.status;

      console.log("Status");
      console.log(task.status);
    },

    hideMenu() {
      let newMenu = document.getElementById("newMenu");
      newMenu.style.visibility = "hidden";
    },

    onRightClick(event, task) {
      this.selected_task = task;

      let newMenu = document.getElementById("newMenu");
      let x = event.clientX - 300;
      let y = event.pageY - 250;

      let winWidth = window.innerWidth;
      let cmWidth = newMenu.offsetWidth;

      x = event.clientX > winWidth - cmWidth ? winWidth - cmWidth - 300 : x;

      newMenu.style.top = y + "px";
      newMenu.style.left = x + "px";
      newMenu.style.visibility = "visible";
    },

    limparObra() {
      this.titulo_btn = "Cadastrar";
      this.titulo_modal = "Nova task";
      this.acao = "store";

      this.formData.project_id = "";
      this.formData.requester = "";
      this.formData.type = "";
      this.formData.description = "";
      this.formData.title = "";
      this.formData.responsable = "";
    },

    async validate() {
      this.formData.update_task = true;

      this.$nextTick(async function () {
        const result = await this.v$.$validate();

        if (result) {
          //this.storeComment();
          console.log("Passou ");
          this.update();
        } else {
          console.log(this.v$);
        }
      });
    },

    searchComments() {
      let data = {
        task_id: this.task.id,
        inicio: this.inicio,
        tamanho: this.qtd_por_pagina,
      };

      console.log("Cadastrando");
      console.log(data);

      axios
        .post(`/admin/comments/search_by_task`, data)
        .then((response) => {
          this.comments = response.data.comments;

          this.pagination(response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          this.showErrorMessageWithButton("Ops..", error);
          console.log(error);
        });
    },

    deleteComment(comment) {
      this.$swal
        .fire({
          title: "<h3 style='color:#616060'>Deseja deletar o comentário<br/><b>" + comment.comments + "</b>? </h3>",
          icon: "warning",
          padding: "1.5em",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, quero deletar!",
        })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;

            let data = {
              comment_id: comment.id,
            };

            axios
              .post(`/admin/comment/delete`, data)
              .then((response) => {
                this.isLoading = false;
                this.showSuccessMessageWithButton("Sucesso", "Comentário deletado");
                this.searchComments();
              })
              .catch((error) => {
                this.isLoading = false;
                this.showErrorMessageWithButton("Ops..", error.response.data);
                console.log(error.response.data);
              });
          }
        });
    },

    update() {
      this.isLoading = true;

      let data = {
        task_id: this.task.id,
        project_id: this.formData.project_id,
        requester: this.formData.requester,
        type: this.formData.type,
        description: this.formData.description,
        title: this.formData.title,
        responsable: this.formData.responsable,
      };

      console.log("Atualizando");
      console.log(data);

      axios
        .post(`/admin/task/update`, data)
        .then((response) => {
          this.isLoading = false;

          this.showSuccessMessageWithButton("Sucesso", "Task atualizada");
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error);
          console.log(error);
        });
    },

    calcularValor(created_at) {
      let dataReferencia = this.formatToCompare(new Date());
      let data = this.formatToCompare(created_at);

      var duration = moment.duration(dataReferencia.diff(data));
      var hours = duration.asHours();

      if (hours < 1) {
        var min = duration.asMinutes();
        return ~~min + " min.";
      } else if (hours >= 24) {
        var days = duration.asDays();
        return ~~days + " dias";
      } else {
        return ~~hours + " horas";
      }
    },

    hideMenu() {
      let newMenu = document.getElementById("newMenu");
      newMenu.style.visibility = "hidden";
    },

    onChange(e) {
      this.formData.upload_files = e.target.files[0];
    },

    visualizar(leilao) {
      this.leilao_selecionado = leilao;
    },

    formatToCompare(date) {
      return moment(date);
    },

    convertTiny(value) {
      if (value == 0) {
        return "Não";
      } else {
        return "Sim";
      }
    },

    convertToTiny(value) {
      if (value) {
        return 1;
      } else {
        return 0;
      }
    },

    getUrlEdit(leilao) {
      return "/admin/leilao/" + leilao.id;
    },

    show(leilao) {
      this.leilao_selecionado = leilao;
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.buscartasks();
      }
    },

    buscartasks() {
      this.isLoading = true;

      let data = {
        id: this.id,
        inicio: this.inicio,
        filtro: this.filtro,
        tamanho: this.qtd_por_pagina,
      };

      console.log(data);

      if (this.filtro && this.filtro != "") {
        this.searchBuscado = true;
      } else {
        this.searchBuscado = false;
      }

      axios
        .post(`/admin/tasks/search`, data)
        .then((response) => {
          this.isLoading = false;
          this.tasks = response.data.tasks;
          this.total = response.data.total;

          console.log("tasks");
          console.log(this.tasks);

          this.pagination(response.data);
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    getImagePath(logo) {
      return process.env.MIX_VUE_APP_LOGOS_ENDPOINT + "/" + logo.caminho_foto;
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.buscartasks();
    },

    deletar(obra) {
      this.$swal
        .fire({
          title: "<h3 style='color:#616060'>Deseja deletar a obra<br/><b>" + obra.titulo + "</b>? </h3>",
          icon: "warning",
          padding: "1.5em",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, quero deletar!",
        })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;

            let data = {
              obra_id: obra.id,
            };

            axios
              .post(`/admin/obra/delete`, data)
              .then((response) => {
                this.isLoading = false;
                this.showSuccessMessageWithButtonAndRedirect("Sucesso", "Obra deletada", "/admin");
              })
              .catch((error) => {
                this.isLoading = false;
                this.showErrorMessageWithButton("Ops..", error.response.data);
                console.log(error.response.data);
              });
          }
        });
    },

    validateFields() {
      if (!this.nome) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else {
        this.create();
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm");
    },
  },
};
</script>

<style scoped>
.current-page {
  background-color: #428bca !important;
  color: white;
}

.modal-content {
  width: 1000px;
  margin-left: -150px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
