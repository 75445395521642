<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div>
    <br />
    <h2>
      Testes
      <button
        data-toggle="modal"
        data-target="#create-test-modal"
        style="margin-left: 15px"
        type="button"
        class="btn btn-primary"
        @click="create_test = true"
      >
        <i class="fas fa-plus" style="margin-right: 5px"></i>
        Novo teste
      </button>
    </h2>

    <create-test v-if="create_test" :task="task" :setCreateTest="setCreateTest" :testCreated="testCreated" />
    <edit-test v-if="edit_test" :test="selected_test" :setEditTest="setEditTest" :testEdited="testEdited" />

    <!-- TESTES -->
    <div class="card" id="list_tests">
      <div class="card-body table-responsive p-0">
        <table class="table table-hover text-nowrap" style="position: relative">
          <thead>
            <tr>
              <th>Ações</th>
              <th>Id</th>
              <th>Status</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(test, index) in tests" :key="test" @contextmenu.prevent="onRightClick($event, test)" @click="hideMenu()">
              <td data-label="Editar" style="width: 30px !important; text-align: center">
                <a id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-pre>
                  <i class="fa-solid fa-bars" style="color: gray"></i>
                </a>

                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" aria-labelledby="navbarDropdown" ref="menu">
                  <div class="dropdown-divider"></div>
                  <a @click="selectTest(test), (edit_test = true)" data-toggle="modal" data-target="#edit-test-modal" class="dropdown-item">
                    <i class="fa-solid fa-pen"></i>
                    &nbsp;&nbsp; Editar
                  </a>

                  <div class="dropdown-divider"></div>
                  <a @click="deletar(test)" class="dropdown-item">
                    <i class="fa-solid fa-bars"></i>
                    &nbsp;&nbsp; Deletar
                  </a>
                </div>
              </td>
              <td data-label="Id">{{ test.id }}</td>
              <td data-label="Status">{{ test.status }}</td>
              <td data-label="Descrição">{{ getFormatedDescription(test.description) }}</td>
            </tr>
          </tbody>
        </table>

        <div
          id="newMenu"
          style="visibility: hidden; position: absolute; background-color: white; max-width: 300px; line-height: 15px"
          class="dropdown-menu-lg dropdown-menu-right"
        >
          <div class="dropdown-divider"></div>
          <a style="cursor: pointer" @click="selectTest(selected_test)" class="dropdown-item" data-toggle="modal" data-target="#edit-test-modal">
            <i class="fa-solid fa-pen"></i>
            &nbsp;&nbsp; Editar
          </a>

          <div class="dropdown-divider"></div>
          <a style="cursor: pointer" @click="deletar(selected_test)" class="dropdown-item">
            <i class="fa-solid fa-bars"></i>
            &nbsp;&nbsp; Deletar
          </a>
        </div>
      </div>
      <div class="card-footer clearfix">
        <!-- TOTAIS  -->
        <div style="font-size: 13px" class="float-left" aria-live="polite">
          Exibindo do {{ inicio + 1 }} ao {{ inicio + tests.length }}
          <p>Total {{ total }}</p>
        </div>

        <p class="card-title" style="font-size: 12px; color: black; margin-right: 5px; margin-left: 10px">Por página</p>

        <select @change="alterarQtdPagina()" class="float-left card-titlecustom-select rounded-0" style="font-size: 12px" v-model="qtd_por_pagina">
          <option :value="1">1</option>
          <option :value="2">2</option>
          <option :value="7">7</option>
          <option :value="15">15</option>
          <option :value="25">25</option>
        </select>

        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 150px"></div>
        </div>

        <!-- PAGINAÇÃO  -->
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item"><a class="page-link" href="#list_tests" @click="selecionarPagina(pagina_atual - 1)">&laquo;</a></li>

          <li v-for="pagina in paginas" :key="pagina" :value="pagina" class="page-item">
            <a
              href="#list_tests"
              :class="pagina === pagina_atual ? 'page-link current-page' : 'stopped'"
              class="page-link"
              @click="selecionarPagina(pagina)"
            >
              {{ pagina }}
            </a>
          </li>

          <li class="page-item"><a class="page-link" href="#list_tests" @click="selecionarPagina(pagina_atual + 1)">&raquo;</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import sweetAlert from "../../controller/sweetAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AuxController from "../../mixins/auxController.js";
import Loading from "vue-loading-overlay";
import moment, { relativeTimeThreshold } from "moment";
import "vue-loading-overlay/dist/vue-loading.css";

import useVueLidate from "@vuelidate/core";
import { required } from "../../locales/i18n.js";
import { computed, reactive } from "vue";

export default {
  props: ["task"],

  mixins: [sweetAlert, Swal, AuxController],

  components: { Loading },

  data() {
    return {
      isLoading: false,
      filtro: "",
      paginas: [],
      inicio: 0,
      total: 0,
      qtd_por_pagina: 25,
      pagina_atual: 1,
      tests: [],
      acao: "",
      titulo_btn: "",
      titulo_modal: "",
      selected_test: "",
      create_test: false,
      edit_test: false,
    };
  },

  setup() {
    const formData = reactive({
      teste: false,
    });

    const rules = computed(() => {
      return {};
    });

    const v$ = useVueLidate(rules, formData);

    return { formData, v$ };
  },

  mounted() {
    this.searchTests();
  },

  methods: {
    getFormatedDescription(description) {
      let max_size = 120;

      if (description && description.length > max_size) {
        return description.substring(0, max_size) + "...";
      } else {
        return description;
      }
    },

    testEdited() {
      let btn = document.getElementById("close_edit_test");

      btn.click();

      this.setEditTest(false);

      this.searchTests();
    },

    setEditTest(value) {
      let btn = document.getElementById("close_edit_test");
      if (btn) {
        btn.click();
      }

      this.edit_test = value;
    },

    testCreated() {
      let btn = document.getElementById("close_create_test");

      btn.click();

      this.setCreateTest(false);

      this.searchTests();
    },

    setCreateTest(value) {
      let btn = document.getElementById("close_create_test");
      if (btn) {
        btn.click();
      }

      this.create_test = value;
    },

    hideMenu() {
      let newMenu = document.getElementById("newMenu");
      newMenu.style.visibility = "hidden";
    },

    onRightClick(event, test) {
      this.selected_test = test;

      let newMenu = document.getElementById("newMenu");
      let x = event.clientX - 300;
      let y = event.pageY - 630;

      let winWidth = window.innerWidth;
      let cmWidth = newMenu.offsetWidth;

      x = event.clientX > winWidth - cmWidth ? winWidth - cmWidth - 300 : x;

      newMenu.style.top = y + "px";
      newMenu.style.left = x + "px";
      newMenu.style.visibility = "visible";
    },

    limparObra() {
      this.titulo_btn = "Cadastrar";
      this.titulo_modal = "Novo teste";
      this.acao = "store";

      this.formData.project_id = "";
      this.formData.requester = "";
      this.formData.type = "";
      this.formData.description = "";
      this.formData.title = "";
      this.formData.responsable = "";
    },

    selectTest(test) {
      this.selected_test = test;
    },

    async validate() {
      const result = await this.v$.$validate();

      if (result) {
        if (this.acao == "store") {
          this.store();
        } else {
          this.update();
        }
      }
    },

    hideMenu() {
      let newMenu = document.getElementById("newMenu");
      newMenu.style.visibility = "hidden";
    },

    onChange(e) {
      this.formData.upload_files = e.target.files[0];
    },

    formatToCompare(date) {
      return moment(date);
    },

    getUrlEdit(leilao) {
      return "/admin/leilao/" + leilao.id;
    },

    show(leilao) {
      this.leilao_selecionado = leilao;
    },

    selecionarPagina(pagina) {
      if (pagina <= this.paginas.length && pagina > 0) {
        this.inicio = this.qtd_por_pagina * (pagina - 1);
        this.pagina_atual = pagina;
        this.searchTests();
      }
    },

    searchTests() {
      this.isLoading = true;

      let data = {
        task_id: this.task.id,
        inicio: this.inicio,
        filtro: this.filtro,
        tamanho: this.qtd_por_pagina,
      };

      console.log(data);

      axios
        .post(`/admin/tests/search_by_task`, data)
        .then((response) => {
          this.isLoading = false;
          this.tests = response.data.tests;
          this.total = response.data.total;

          console.log("tests");
          console.log(this.tests);

          this.pagination(response.data);
        })
        .catch((error) => {
          this.showErrorMessageWithButton("Ops..", error.response.data);
          console.log(error.response.data);
        });
    },

    getImagePath(logo) {
      return process.env.MIX_VUE_APP_LOGOS_ENDPOINT + "/" + logo.caminho_foto;
    },

    pagination(data) {
      this.paginas = [];

      let length = data.total / this.qtd_por_pagina;
      let i = 0;

      //DEFININDO MÁXIMO E MÍNIMO DE PÁGINAS
      if (length < 0) {
        length = 0;
      } else if (length > 10) {
        if (this.pagina_atual >= 10) {
          let minimo = this.pagina_atual - 10;

          if (length - this.pagina_atual > 10) {
            length = this.pagina_atual + 10;
            i = minimo;
          } else {
            i = this.pagina_atual - 10 + (length - this.pagina_atual);
          }
        } else {
          length = 10;
        }
      }

      for (i; i < length; i++) {
        this.paginas.push(i + 1);
      }
    },

    alterarQtdPagina() {
      this.pagina_atual = 1;
      this.inicio = 0;

      this.searchTests();
    },

    deletar(test) {
      this.$swal
        .fire({
          title: "<h3 style='color:#616060'>Deseja deletar o teste<br/><b>" + test.description + "</b>? </h3>",
          icon: "warning",
          padding: "1.5em",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, quero deletar!",
        })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;

            let data = {
              test_id: test.id,
            };

            axios
              .post(`/admin/test/delete`, data)
              .then((response) => {
                this.isLoading = false;
                this.showSuccessMessageWithButton("Sucesso", "Teste deletado");
                this.searchTests();
              })
              .catch((error) => {
                this.isLoading = false;
                this.showErrorMessageWithButton("Ops..", error.response.data);
                console.log(error.response.data);
              });
          }
        });
    },

    validateFields() {
      if (!this.nome) {
        this.showErrorMessageWithButton("Ops...", "O campo nome é obrigatório!");
      } else {
        this.create();
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm");
    },
  },
};
</script>

<style scoped>
.current-page {
  background-color: #428bca !important;
  color: white;
}

.modal-content {
  width: 1000px;
  margin-left: -150px;
}

@media screen and (max-width: 1200px) {
  .modal-content {
    width: 800px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 600px;
    margin-left: -50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 100px;
  }
}
</style>
